.loaderOverlay {
  /* position: absolute; */
  z-index: 1000;
  opacity: 60;
  filter: alpha(opacity=50);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000082;
  color: White;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  font-weight: bolder;
  margin-top: -12px;
}