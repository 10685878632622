.clock {
  width: 230px;
  height: 230px;
  position: relative;
  /* left: 20%; */
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.face {
  position: absolute;
  background: white;
  height: 80%;
  width: 80%;
  top: 10%;
  left: 10%;
  border: 6px solid;
  border-radius: 50%;
  box-shadow: 0 1px 25px #b0dccb;
}

.hand {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -100%);
  transform-origin: center top;
  border-radius: 4px;
}

.second {
  height: 85px;
  width: 2px;
  background-color: gold;
}

.hour {
  height: 60px;
  width: 4px;
  background-color: #000;
}

.minute {
  height: 75px;
  width: 6px;
  background-color: #000;
  border-top-right-radius: 0mm;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 90%;
}

.center {
  background-color: white;
  width: 4px;
  height: 3px;
  border: 6px solid;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.face span {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 16px;
}

.twelve {
  left: 46%;
}

.one {
  top: 10%;
  right: 26%;
}

.eleven {
  top: 10%;
  left: 26%;
}

.two {
  top: 25%;
  right: 10%;
}

.three {
  right: 10px;
  top: 46%;
  transform: rotate(90deg);
}

.four {
  right: 30px;
  top: 67%;
}

.five {
  right: 60px;
  top: 80%;
}

.six {
  bottom: 5px;
  left: 50%;
}

.seven {
  left: 68px;
  top: 80%;
}

.eight {
  left: 30px;
  top: 67%;
}

.nine {
  left: 10px;
  top: 46%;
  transform: rotate(90deg);
}

.ten {
  top: 25%;
  left: 10%;
}

.countryName {
  display: flex;
  place-content: flex-start center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.noData {
  margin-top: 100px;
}