.dx-datagrid-headers {
  font-weight: 600 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.dx-datagrid-headers .dx-datagrid-content {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.dx-datagrid {
  font-family: "myriad-pro", sans-serif;
  font-size: 16px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row>td {
  height: 56px !important;
  padding: 20px 7px 7px 7px !important;
  text-align: left !important;
  border-right: 1px solid #ddd;
  background-color: #3f4443 !important;
}

.dx-texteditor-container {
  border: 1px solid #e6e1e1 !important;
}

.dx-header-row {
  background-color: #3f4443 !important;
  color: #ffffff !important;
}

/* .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  border: 1px solid red !important;
} */

.dx-datagrid {
  max-width: 100% !important;
}

/* .borderlessGrid .dx-data-row > td {
  border-right: 0px;
  border-left: 0px;
} */

.dx-datagrid-nodata {
  color: #dc3545 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'myriad-pro', sans-serif;
}

/* .dx-submenu,
.dx-context-menu {
  visibility: hidden !important;
} */

.dx-state-hover {
  border: 0 !important;
  background-color: transparent !important;
}

.dx-submenu .dx-menu-items-container {
  min-width: 100px;
}

.dx-editor-outlined,
.dx-editor-outlined:hover,
.dx-editor-outlined:focus {
  border: none !important;
  background-color: #ffffff !important;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  text-align: center !important;
}

.dx-checkbox {
  display: inline-block !important;
}

.dx-checkbox-container {
  border: 1px solid gray;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td {
  background-color: #f5fcff;
}