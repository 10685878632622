.App {
  background-color: #fafafa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Scroll*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #183650;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  border: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
.MuiAutocomplete-option {
  border-top: 1px solid #00a3e0;
  font-size: 16px;
  font-weight: normal;
  color: #212529;
}
.MuiAutocomplete-option:hover {
  background-color: #00a3e033;
}
.myCustomList li {
  border-top: 1px solid rgb(246 246 246);
}
.commonMargin {
  margin-top: 10px;
  margin-bottom: 150px;
}
.OverLay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.table-container {
  position: relative;
  /* disable user selection */
  user-select: text;
}

.box-wrapper {
  position: relative;
}

.box-wrapper .full-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  backdrop-filter: blur(1px);
}

.box-wrapper .full-overlay .MuiCircularProgress-root {
  transform: translate(50%, 50%);
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 39%;
}

.visible {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.filterContainer {
  background-color: #eeeeee;
  /* max-height: 60px; */
  margin-bottom: 20px;
  padding: 2px 20px;
}

.search {
  border: none;
  height: 30px;
  width: 350px;
  font: inherit;
  padding-left: 15px;
}

.search:focus-visible {
  outline: none;
}

.datePicker {
  background: white;
  border: none;
  border-bottom: none;
  margin: 3px;
  height: 30px;
  width: 100%;
  font: inherit;
  padding-left: 15px;
}

.react-datepicker-popper {
  z-index: 110 !important;
  padding-top: 0 !important;
}

.btnContainer {
  align-items: center;
  justify-content: flex-end;
}

.applyBtn {
  background-color: #183650 !important;
  color: #ffffff !important;
  border: 1px solid #9e9e9e;
  box-shadow: 0px 3px 4px #a9b8c5;
  font-size: 14px;
  height: 30px;
}

.clearBtn {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px solid #9e9e9e;
  box-shadow: 0px 3px 4px #00000029;
  font-size: 14px;
  height: 30px;
}

.nextBtn:disabled,
.nextBtn[disabled] {
  width: 70px !important;
  height: 35px !important;
  color: #ffffff !important;
  background: #18365072 0% 0% no-repeat padding-box !important;
  text-transform: capitalize !important;
  box-shadow: 0px 2px 6px #18365072;
  border-radius: 2px !important;
}

.nextBtn {
  width: 70px;
  height: 35px;
  background-color: #183650 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  border: 1px solid #9e9e9e !important;
  border-radius: 2px !important;
  opacity: 1;
}

.nextBtn:hover {
  background-color: #183650;
}
/* .customDateInput {
  background: url("../public/calendar.svg") no-repeat right;
  background-color: white;
  border: none;
  border-bottom: none;
  margin-top: 5px;
  width: -webkit-fill-available;
  height: 36px;
  padding-left: 10px;
  background-position: 95%;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans";
  position: inherit !important;
  box-shadow: 2px 2px 6px #0000000d;
}
.customDateInput:hover {
  outline: none;
  background-color: #34ccff1a;
}
.customDateInput::placeholder {
  letter-spacing: "0.01px";
  color: #6c757d;
  opacity: 1;
  font-weight: 600;
}
.customDateInput::after {
  border: none !important;
  border-color: transparent !important;
}
.customDateInput:disabled {
  background-color: grey;
  color: white !important;
} */
.react-datepicker__header {
  background-color: #183650 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  border: 1px solid #6cbed9 !important;
  margin: 0.066rem !important;
  background-color: transparent !important;
  color: black !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #183650 !important;
}

.react-datepicker__time-list-item:hover {
  background-color: #6cbed9 !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
  border: 1px solid #6cbed9 !important;
  margin: 0.066rem !important;
  background-color: transparent !important;
  color: black !important;
}

.react-datepicker__day:hover {
  background-color: #6cbed9 !important;
  color: #fff !important;
}

.react-datepicker__day--today {
  border-radius: 50% !important;
  border: 1px solid #6cbed9 !important;
  margin: 0.066rem !important;
  background-color: transparent !important;
  color: black !important;
}

.addBtn {
  color: #183650;
}

.MuiSlider-markLabel {
  top: -14px !important;
  color: #6c757d !important;
}

.dashboardHeader {
  background-color: #00a3e0 !important;
}

#directSummaryGrid,
#emptyContainerGrid,
#availableCapacityGrid {
  transform: none !important;
}

#tracking-map,
#basicMap > .mapboxgl-canvas-container {
  height: 360px;
}
#tracking-map #basicMap > .mapboxgl-canvas {
  width: 100% !important;
}
#tracktrace #basicMap > .mapboxgl-canvas-container {
  height: 360px;
}
#tracktrace #basicMap > .mapboxgl-canvas {
  width: 100% !important;
}

.mapboxgl-canvas {
  border-radius: 12px !important;
}

.dx-datagrid-header-panel {
  display: none !important;
}

#mapDialog > .mapboxgl-canvas-container {
  height: 560px;
}
#mapDialog > .mapboxgl-canvas {
  width: 1000px !important;
  height: 560px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
